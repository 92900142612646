import React from "react";
import classNames from "classnames";
import Image from "../../elements/Image";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a
            href="https://www.linkedin.com/in/satyam04/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={require("../../../assets/images/linkedin.svg")}
              alt="Linkedin"
              width={22}
              height={22}
            />
          </a>
        </li>
        <li>
          <a
            href="https://medium.com/@satyamsaluja04"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={require("../../../assets/images/medium1.svg")}
              alt="Medium"
              width={22}
              height={22}
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC0okH129pUhqADnOqBInggA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={require("../../../assets/images/youtube.svg")}
              alt="Youtube"
              width={25}
              height={25}
              style={{transform: "translate(0px, 3px)"}}
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
