import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import FooterSocial from "../layout/partials/FooterSocial";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };
  const redirectToFakeContributionArticle = () => {
    // Open Google in a new tab
    window.open(
      "https://dev.to/shinshin86/faking-github-contributions-2jpe",
      "_blank"
    );
  };

  const showConfirmationAlert = () => {
    // Display the confirmation alert
    const confirmation = window.confirm(
      "Are you sure? Github contributions are still a thing in 2024?😮"
    );

    // If user confirms, redirect to Google
    if (confirmation) {
      redirectToFakeContributionArticle();
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            {/* <div className="down">
              <a href="#skills">Scroll down</a>
            </div> */}
            <Image
              className="has-shadow"
              src={require("./../../assets/images/dp-otw.png")}
              alt="Hero"
              width={200}
              height={200}
              style={{
                borderRadius: "50%",
                marginBottom: "30px",
                aspectRatio: "1",
                objectFit: "cover",
              }}
            />
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Hi There! I'm <span className="text-color-primary">Satyam! </span>
            </h1>
            <div className="container-xs">
              <p className="m-0 reveal-from-bottom" data-reveal-delay="400">
                I'm a Senior Software Engineer currently @Moove Dubai 🇦🇪
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                I'm the one who dances with pixels, tangoes with code, and turns
                coffee into clean interfaces(web & mobile).
              </p>

              <FooterSocial />

              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="mailto:satyam.dev04@gmail.com"
                  >
                    Hire me!
                  </Button>
                  {/* <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    onClick={showConfirmationAlert}
                  >
                    View on Github
                  </Button> */}
                  <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    href="tel:+971557794319"
                  >
                    Call Now!
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>

          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
            {/* <Image
              className="has-shadow"
              src={require("./../../assets/images/placeholder1.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            /> */}
            {/* </a> */}
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
