import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Work Experience",
    paragraph:
      "I have total 5+ years of experience in software development. I've mastered the art of creating awesome Web and Mobile applications",
  };

  return (
    <section id="experience" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  March 2023 - present
                </div>
                <h3 className="mt-0 mb-12">
                  Senior Software Engineer -{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://www.moove.io/"
                    target="_blank"
                  >
                    Moove
                  </a>
                  , Dubai
                </h3>
                <ul>
                  <li>
                    <p className="m-0">
                      Working as a mobile and web engineer to create Moove
                      driver app across iOS and Android platforms using
                      <span className="text-color-secondary">
                        {" "}
                        React Native
                      </span>
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Owning and leading the wallet module, a key fintech
                      feature with 10k active drivers using it for remittances,
                      driving business revenue.
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Building delivery layer APIs using{" "}
                      <span className="text-color-secondary">
                        NestJS & Typescript{" "}
                      </span>
                      for seemless integrations with driver, plan and vehicles.
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Integrated biometrics for the wallet module in the Moove
                      driver mobile app, enabling seamless sign-in and wallet
                      transactions.
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Helped in end to end release process for iOS & Android
                      apps.
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Built an internal fleet management tool for the operations
                      team using Retool, a no-code platform, significantly
                      improving delivery speed and efficiency.
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/moove-banner.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                  className="split-img"
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  October 2021 - Janurary 2023
                </div>
                <h3 className="mt-0 mb-12">
                  Software Engineer -{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://www.coinbase.com/"
                    target="_blank"
                  >
                    Coinbase
                  </a>
                </h3>
                <ul>
                  <li>
                    <p className="m-0">
                      Working with{" "}
                      <span className="text-color-secondary">ReactJS</span>,
                      <span className="text-color-secondary">
                        {" "}
                        React Native{" "}
                      </span>
                      and <span className="text-color-secondary"> NextJS </span>
                      to provide, premium web and mobile experience for all our
                      crypto savy users!
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Lead major redesign of Coinbase HelpCenter with new UI
                      components aligning to Coinbase{" "}
                      <span className="text-color-secondary">
                        Design System
                      </span>
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Implemented a headless CMS solution using{" "}
                      <span className="text-color-secondary">
                        Contentful and Next.js
                      </span>
                      , enabling customer agents to efficiently author and host
                      key articles and blogs on help.coinbase.com
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Introduced unit tests({" "}
                      <span className="text-color-secondary">jest</span>) &
                      automated tests(
                      <span className="text-color-secondary">puppeteer</span>)
                      and achieved an overall code coverage close to 80%
                    </p>
                  </li>

                  <li>
                    <p className="m-0">
                      Learning web3 protocols to enable economic freedom around
                      the world!
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/coinbase_banner.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className="split-img"
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  August 2020 - October 2021
                </div>
                <h3 className="mt-0 mb-12">
                  Software Engineer -{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://invideo.io/"
                    target="_blank"
                  >
                    InVideo
                  </a>
                  , Mumbai
                </h3>
                <ul>
                  <li>
                    <p className="m-0">
                      Working on the best browser based video editing platform
                      in the world!
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Working on improving load time and performance by tackling
                      memory leaks and costly DOM manipulation
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Building robust, scalable and modular architectures
                    </p>
                  </li>
                  <li>
                    <p>Exploring canvas and WebGL to create magic!</p>
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/hackernoon.jpeg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className="split-img"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Janurary 2018 - August 2020
                </div>
                <h3 className="mt-0 mb-12">
                  SDE & SDE Intern - Devslane, Delhi NCR
                </h3>
                <ul>
                  <li>
                    <p className="m-0">
                      Worked on cutting edge frontend technologies to ship WEB &
                      Mobile softwares
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Designed, developed and maintained well architectured
                      code, following clean coding priniciples
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Worked on some cool usecases - Geo-location services
                      including Geo-fencing, Live location tracking, Route
                      mapping etc
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Client handling - Requirement analysis, estimation,
                      decision making
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Resource management - Managed and mentored 3+ interns.
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/web_dev.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                  className="split-img"
                />
              </div>
            </div>

            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Janurary 2018 - June 2019
                </div>
                <h3 className="mt-0 mb-12">
                  Frontend Intern - Devslane, Delhi NCR
                </h3>
                <ul>
                  <li>
                    <p className="m-0">
                      Worked under iOS core team to develop & mantain large
                      scale iOS applications
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Worked on some cool usecases - Geo-location services
                      including Geo-fencing, Live location tracking, Route
                      mapping etc
                    </p>
                  </li>
                  <li>
                    <p className="m-0">
                      Developed and maintained Web Applications to improve
                      performance and deliver new features
                    </p>
                  </li>
                  <li>
                    <p>
                      Learnt and acquired WEB and Mobile development skills
                      under close mentorship of some highly skilled individuals
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/app_dev.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  className="split-img"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
