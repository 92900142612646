import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "./../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Blogs",
    paragraph:
      "Blogs are my way of giving back to the community that I am connected with! Few of my works includes :",
  };

  return (
    <section id="blogs" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div>
                  <Image
                    src={require("../../assets/images/medium1.svg")}
                    alt="Medium"
                    width={22}
                    height={22}
                    style={{ margin: "10px" }}
                  />
                  <h5>
                  Demystifying Asynchronous JavaScript — Event Loop, Call stack, Task Queue, and More!
                  </h5>
                  <p className="text-sm mb-0">
                    — JavaScript is a single threaded programming language. This means that only one piece of code can run at a time, on a single main thread, and everything else is blocked...
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">
                    Read more
                  </span>
                  <span className="text-color-low"> : </span> */}
                  <span className="testimonial-item-link">
                    <a
                      href="https://levelup.gitconnected.com/demystifying-asynchronous-javascript-event-loop-call-stack-task-queue-and-more-51fb748a4bd4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div>
                  <Image
                    src={require("../../assets/images/medium1.svg")}
                    alt="Medium"
                    width={22}
                    height={22}
                    style={{ margin: "10px" }}
                  />
                  <h5>
                  5 CSS tips and tricks you don’t want to miss out on in 2021
                  </h5>
                  <p className="text-sm mb-0" style={{ minHeight: "140px" }}>
                    — Before talking about CSS trends in 2021, let me take you back to 1994 when the web is starting to be used as a platform for electronic publishing. However, one important part of a publishing platform is missing...
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">
                    Read more
                  </span>
                  <span className="text-color-low"> : </span> */}
                  <span className="testimonial-item-link">
                    <a
                      href="https://levelup.gitconnected.com/5-css-tips-and-tricks-you-dont-want-to-miss-out-on-in-2021-4eb1438dee50"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div>
                  <Image
                    src={require("../../assets/images/medium1.svg")}
                    alt="Medium"
                    width={22}
                    height={22}
                    style={{ margin: "10px" }}
                  />
                  <h5 style={{ minHeight: "117px" }}>
                  Animate a social share button using Implicit animations in Flutter
                  </h5>
                  <p className="text-sm mb-0" style={{ minHeight: "140px" }}>
                    — Good animations make the interface user-friendly and creative. Adding animations with Flutter is a bliss! In Flutter, there are 2 ways to add animations: Implicit animations and Explicit...
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* <span className="testimonial-item-name text-color-high">
                    Read more
                  </span>
                  <span className="text-color-low"> : </span> */}
                  <span className="testimonial-item-link">
                    <a
                      href="https://levelup.gitconnected.com/animate-a-social-share-button-using-implicit-animations-in-flutter-9b2b86dd6594"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="testimonial-item-link read-all-link">
                    <a
                      href="https://medium.com/@satyamsaluja04"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read All 📚
                    </a>
                  </span>
    
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
