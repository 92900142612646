import React from "react";
import classNames from "classnames";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <div style={{ display: "flex" }}>
          <Image
            src={require("./../../../assets/images/animat-diamond-color.gif")}
            alt="Open"
            width={100}
            height={100}
          />
          <p
            className="m-0"
            style={{
              fontFamily: "Satisfy, cursive",
              fontSize: "25px",
              color: "white",
              alignSelf: "center",
              fontWeight: "initial",
              marginTop: "10px",
            }}
          >
            Satyam Saluja
          </p>
        </div>
      </h1>
    </div>
  );
};

export default Logo;
